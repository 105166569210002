<template>
    <div>
        <div class="title">
          <h2>{{ $t('menu.raids') }}</h2>
        </div>
        <SelectCity class="select-city" @setCity="setCity" :raid="true" />
        <div class="type mt-3">
            <div class="type_content flex justify-center" :class="{
              active: select.filter(i => i.value == r.value).length
            }" v-for="(r, k) in reids" :key="k" @click.prevent="getDados(r)"
                >
                <div>
                    <img style="height: 30px" v-if="r.img" :src="r.img" />
                </div>
                <div class="absolute">
                    <img style="height: 30px" v-if="r.r" :src="shadow" />
                </div>
                <div class="rel">
                    <img style="height: 10px" v-if="r.r" :src="rocket" />
                </div>
            </div>
        </div>
        <div>
            <Loading :active="loading" />
            <div class="check-events" v-if="!itens.length">
              {{ $t('raid.semraids') }}
            </div>
            <div v-for="(i, k) in itens" :key="k" class="reides" :class="[
              { mystic: i.team === 1 },
              { valor: i.team === 2 },
              { instinct: i.team === 3 }
            ]">
                <div class="content-img flex justify-center">
                    <img class="igg" :src="i.base" @error="getImage" />
                </div>
                <div class="content">
                    <div>
                        <strong>#{{ i.pokemon_id }} {{ i.name[0][1] }}</strong>
                    </div>
                    <div>
                        <div class="base">
                            <div class="left title">
                                <div>{{ $t('raid.tier') }}: {{ i.level }}</div>
                                <div>{{ $t('raid.cp') }}: {{ i.cp }}</div>
                                <div>
                                    {{ $t('raid.gender') }}: {{ getGenderString(i.gender) }}
                                </div>
                                <div v-if="i.team">
                                    {{ $t('raid.team') }}:
                                    <img style="height: 20px" :src="getTime(i.team)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="esq">
                    <div class="coordenada">{{ i.coord }}</div>
                    <div>
                        <vue-flip active-click width="100px" height="45px" class="btn-content">
                            <template v-slot:front>
                                <div class="btn" @click="getCopy(i.coord)">
                                    {{ $t('locais.copiar') }}
                                </div>
                            </template>
                            <template v-slot:back>
                                <div class="btn" @click="getCopy(i.coord)">=)</div>
                            </template>
                        </vue-flip>
                    </div>
                    <div class="tempo">
                        <div>
                            <countdown :time="i.time * 1000" :transform="transform">
                                <template slot-scope="props">
                                    DSP: {{ props.minutes }}:{{ props.seconds }}</template>
                            </countdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import SelectCity from '../components/Select'
import name from '../components/Pogo/name'
import Loading from '../components/Loading'
import VueFlip from 'vue-flip'
import Countdown from '@chenfengyuan/vue-countdown'
import { getAnalytics, logEvent } from 'firebase/analytics'
//import dados from '../components/Pogo/shiny'
//import dados from '../components/Pogo/shiny'
const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Raids/'

export default {
    components: {
        SelectCity,
        VueFlip,
        Loading,
        Countdown
    },
    data: () => ({
        loading: false,
        select: [],
        shadow: url + 'shadow_icon.png',
        rocket: url + 'teamrocket_r.png',
        reids: [
            /* {
              title: 'Reide EX',
              value: 'exraid',
              img: require('../assets/raids/ex.png')
            },
            {
              title: 'Egg',
              value: 'egg',
              img: require('../assets/raids/ic_raid_egg_normal.png')
            },
            {
              title: 'Boss',
              value: 'boss',
              img: require('../assets/raids/tx_raid_coin.png')
            },
            {
              title: 'Mystic gyms',
              value: 'mystic',
              team: '1',
              img: require('../assets/raids/team_blue.png')
            },
            {
              title: 'Valor gyms',
              value: 'valor',
              team: '2',
              img: require('../assets/raids/team_red.png')
            },
            {
              title: 'Instinct gyms',
              value: 'instinct',
              team: '3',
              img: require('../assets/raids/team_yellow.png')
            }, */

            {
                title: 'Tier 1',
                value: '1',
                level: '1',
                img: url + 'raid_egg_0_icon.png'
            },
            {
                title: 'Tier 3',
                value: '3',
                level: '3',
                img: url + 'raid_egg_1_icon.png'
            },
            {
                title: 'Tier 5',
                value: '5',
                level: '5',
                img: url + 'raid_egg_2_icon.png'
            },
            {
                title: 'Tier Mega',
                value: '6',
                level: '6',
                img: url + 'raid_egg_3_icon.png'
            },
            {
                title: 'Tier 11',
                value: '11',
                level: '11',
                img: url + 'raid_egg_0_icon.png',
                r: true
            },
            {
                title: 'Tier 13',
                value: '13',
                level: '13',
                img: url + 'raid_egg_1_icon.png',
                r: true
            },
            {
                title: 'Tier 15',
                value: '15',
                level: '15',
                img: url + 'raid_egg_2_icon.png',
                r: true
            },
        ],
        itens: []
    }),
    methods: {
        setCity(item) {
            this.loading = true
            this.lcl = item.slug
            this.itens = []
            this.select = []
            setTimeout(() => {
                this.loading = false
            }, 300)
        },
        getTime(time) {
            switch (time) {
                case 1:
                    return require('../assets/raids/team_blue.png')
                case 2:
                    return require('../assets/raids/team_red.png')
                case 3:
                    return require('../assets/raids/team_yellow.png')
                default:
                    return null
            }
        },
        getGenderString(pokemon) {
            switch (pokemon) {
                case 1:
                    return this.$i18n.locale === 'pt-br'
                        ? 'Masculino'
                        : this.$i18n.locale === 'en-us'
                            ? 'Male'
                            : 'Masculino '
                case 2:
                    return this.$i18n.locale === 'pt-br'
                        ? 'Feminino'
                        : this.$i18n.locale === 'en-us'
                            ? 'Female'
                            : 'Femenino'
                default:
                    return null
            }
        },
        getCopy(r, key) {
            //r.copy = !r.copy
            //const cache = JSON.parse(localStorage.getItem('_rocket'))
            /* cache.map(i => {
              if (i.id === key) {
                i.copy = !r.copy
              }
              return i
            }) */
            key
            //localStorage.setItem('_rocket', JSON.stringify(cache))
            this.$copyText(r).then(
                () => null,
                () => alert('Erro')
            )
        },
        getLugar(lugar) {
            switch (lugar) {
                case 'ca':
                    return 'Vancouver'
                case 'us':
                    return 'Nova York'
                case 'gb':
                    return 'Londres'
                case 'sg':
                    return 'Singapura'
                case 'au':
                    return 'Sydney'
                default:
                    return ''
            }
        },
        transform(props) {
            Object.entries(props).forEach(([key, value]) => {
                const digits = value < 10 ? `0${value}` : value
                props[key] = `${digits}`
            })

            return props
        },
        rad(x) {
            return (x * Math.PI) / 180
        },
        arendondar(num, dec) {
            return (+(Math.round(num + 'e+' + dec) + 'e-' + dec)).toFixed(dec)
        },
        calcularDistancia(lat1, lon1, lat2, lon2) {
            // double R = 6378.137;//Radio de la tierra en km
            var R = 6371.0
            var dLat = this.rad(lat2 - lat1)
            var dLong = this.rad(lon2 - lon1)

            var a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.rad(lat1)) *
                Math.cos(this.rad(lat2)) *
                Math.sin(dLong / 2) *
                Math.sin(dLong / 2)
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
            var d = R * c

            return d
        },
        tempo(dist) {
            var myTime = ''

            if (dist <= 0.01) {
                myTime = '0seg'
            } else if (dist <= 0.1) {
                myTime = '3seg'
            } else if (dist <= 0.5) {
                myTime = '15seg'
            } else if (dist <= 1) {
                myTime = '30seg'
            } else if (dist <= 2) {
                myTime = '1min'
            } else if (dist <= 5) {
                myTime = '2min'
            } else if (dist <= 6) {
                myTime = '4min'
            } else if (dist <= 7) {
                myTime = '5min'
            } else if (dist <= 8) {
                myTime = '6min'
            } else if (dist <= 10) {
                myTime = '7min'
            } else if (dist <= 12) {
                myTime = '8min'
            } else if (dist <= 14) {
                myTime = '9min'
            } else if (dist <= 18) {
                myTime = '10min'
            } else if (dist <= 21) {
                myTime = '13min'
            } else if (dist <= 26) {
                myTime = '15min'
            } else if (dist <= 42) {
                myTime = '19min'
            } else if (dist <= 65) {
                myTime = '22min'
            } else if (dist <= 70) {
                myTime = '24min'
            } else if (dist <= 81) {
                myTime = '25min'
            } else if (dist <= 100) {
                myTime = '35min'
            } else if (dist <= 220) {
                myTime = '40min'
            } else if (dist <= 250) {
                myTime = '45min'
            } else if (dist <= 350) {
                myTime = '51min'
            } else if (dist <= 375) {
                myTime = '54min'
            } else if (dist <= 460) {
                myTime = '1hor 2min'
            } else if (dist <= 500) {
                myTime = '1hor 5min'
            } else if (dist <= 565) {
                myTime = '1hor 9min'
            } else if (dist <= 700) {
                myTime = '1hor 18min'
            } else if (dist <= 800) {
                myTime = '1hor 24min'
            } else if (dist <= 900) {
                myTime = '1hor 32min'
            } else if (dist <= 1000) {
                myTime = '1hor 39min'
            } else if (dist <= 1100) {
                myTime = '1hor 47min'
            } else if (dist <= 1200) {
                myTime = '1hor 54min'
            } else if (dist <= 1300) {
                myTime = '1hor 57min'
            } else {
                myTime = '2hor'
            }
            return myTime
        },
        getDsp(epoch) {
            var hours = epoch / 3600,
                minutes = (hours % 1) * 60
            return (
                (Math.floor(hours) !== 0 ? `${Math.floor(hours)}h` : '') +
                `${Math.floor(minutes)}m`
            )
        },
        leftPad(value, totalWidth, paddingChar) {
            var length = totalWidth - value.toString().length + 1
            return Array(length).join(paddingChar || '0') + value
        },
        getSrc(i) {
            let v
            if (i.value2) {
                v = `${this.leftPad(i.value2, 3)}_00`
            } else if (i.value5) {
                v = `${this.leftPad(i.pokemon_id, 3)}_${i.value5}`
            } else
                if (i.pokemon_id) {
                    let nn
                    if (i.name[0][1].match(/Alola/)) {
                        nn = '.fALOLA'
                    } else if (i.name[0][1].match(/Outono/)) {
                        nn = '.fAUTUMN'
                    } else if (i.name[0][1].match(/Primavera/)) {
                        nn = '.fSPRING'
                    } else if (i.name[0][1].match(/Verão/)) {
                        nn = '.fSUMMER'
                    } else if (i.name[0][1].match(/Inverno/)) {
                        nn = '.fWINTER'
                    }
                    else if (i.name[0][1].match(/Galarian/)) {
                        nn = '.fGALARIAN'
                    } else {
                        if (i.pokemon_id == 327) {
                            nn = '.f00'
                        } else if (i.pokemon_id == 412) {
                            nn = '.fBURMY_PLANT'
                        }
                        else if (i.pokemon_id == 412) {
                            nn = '.fBURMY_PLANT'
                        }
                        else {
                            nn = ''
                        }
                    }
                    v = i.pokemon_id + nn
                }
            const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon/Addressable%20Assets/'
            if (!i.value4) {
                return `${url}pm${v}${i.shiny ? '.s' : ''}.icon.png`
            } else {
                return i.value4
            }
        },
        getImage(e) {
            e.target.src = require('../assets/pokemon/0.png')
        },
        async getDados(r) {
            this.loading = true
            const index = this.select.findIndex(i => i.value === r.value)
            console.log(index, r.value, r, this.select)
            if (index > -1) {
                this.select.splice(index, 1)
            } else {
                this.select.push(r)
            }

            //console.log(this.select)
            const dados = await axios.get(`${this.$url}/raids`, {
                params: {
                    lc: this.lcl
                }
            })

            //console.log(this.select.filter(s => s.input === 'team').map(i => i.value))

            let itens = dados.data.raids.filter(i => {
                const level = this.select.find(
                    v => v.level && [v.level].includes(i.level.toString()) && i.pokemon_id !== 0
                )
                return level
            })

            //console.log(itens)
            itens = itens.map(i => {
                console.log(name)
                i.name = Object.entries(name).filter(x => i.pokemon_id == x[0])
                i.base = this.getSrc(i)
                /* i.base = i.pokemon_id
                    ? require(`../assets/pokemon/${i.pokemon_id}.png`)
                    : null */
                return i
            })

            let dadosI = itens
            var cc = dadosI.map(
                i => `${this.arendondar(i.lat, 6)},${this.arendondar(i.lng, 6)}`
            )
            var listaCC = []

            cc.forEach(i => {
                let x = i.split(',')
                if (x.length === 2) {
                    if (!isNaN(x[0]) && !isNaN(x[1])) {
                        listaCC.push(x)
                    }
                }
            })

            var listaFinal = []
            var distancias = []
            var tam = listaCC.length

            if (tam > 1) {
                var ccInicio = listaCC[0]
                listaCC.splice(0, 1)
                listaFinal.push(ccInicio)
                while (listaCC.length > 1) {
                    var dMin = 1000000000
                    var pos = -1
                    for (let i = 0; i < listaCC.length; i++) {
                        var dx = this.calcularDistancia(
                            ccInicio[0],
                            ccInicio[1],
                            listaCC[i][0],
                            listaCC[i][1]
                        )
                        if (dx < dMin) {
                            pos = i
                            dMin = dx
                        }
                    }
                    listaFinal.push(listaCC[pos])
                    distancias.push(dMin)
                    ccInicio = listaCC[pos]
                    listaCC.splice(pos, 1)
                }
                var coordenadas = []
                var tempos = []
                var penultimo = []
                for (let x = 0; x < listaFinal.length; x++) {
                    coordenadas.push(
                        `${this.arendondar(listaFinal[x][0], 6)},${this.arendondar(
                            listaFinal[x][1],
                            6
                        )}`
                    )
                    if (x === listaFinal.length - 1) {
                        penultimo = listaFinal[x]
                    }
                }
                var ultimo = []
                coordenadas.push(
                    `${this.arendondar(listaCC[0][0], 6)},${this.arendondar(
                        listaCC[0][1],
                        6
                    )}`
                )
                ultimo = listaCC[0]
                // eslint-disable-next-line no-unused-vars
                var distFinal = 0
                tempos.push('0 seg')
                distancias.forEach(x => {
                    distFinal += x
                    tempos.push(this.tempo(x))
                })
                var disRest = this.calcularDistancia(
                    penultimo[0],
                    penultimo[1],
                    ultimo[0],
                    ultimo[1]
                )
                distFinal += disRest
                tempos.push(this.tempo(disRest))
                var set = 1
                // eslint-disable-next-line no-unused-vars
                var calculoFinal = ''
                for (let x = 1; x <= coordenadas.length; x++) {
                    if (x === 1) {
                        calculoFinal += `Total: ${coordenadas.length} \n\nSᴇᴛ ${set}`
                        set++
                    }
                    calculoFinal += `\n${coordenadas[x - 1]} [${tempos[x - 1]}]`
                    if (x % 3 === 0 && x !== coordenadas.length) {
                        calculoFinal += `\n\nSᴇᴛ  ${set}`
                        set++
                    }
                }
                // eslint-disable-next-line no-unused-vars
                var dist = 'Distância:'
            }
            // eslint-disable-next-line no-unused-vars
            var coords = `Coords GO\nRaids\n${this.getLugar(
                this.lcl
            )}\n${moment().format('L')}\n`
            const arrayTag = ['C', 'O', 'O', 'R', 'D', 'S', 'G', 'O']
            var inicio = 0
            var rocket = []
            var totalArrayTag = arrayTag.length

            for (let item of dadosI) {
                var end = item.raid_end - moment().unix()
                if (end > 300) {
                    coords += `${arrayTag[inicio]} ${this.arendondar(
                        item.lat,
                        6
                    )},${this.arendondar(item.lng, 6)} DSP: ${this.getDsp(end)}\n`

                    var rocketNew = {}
                    rocketNew.logo = arrayTag[inicio]
                    rocketNew.coord = `${this.arendondar(item.lat, 6)},${this.arendondar(
                        item.lng,
                        6
                    )}`
                    rocketNew.dsp = this.getDsp(end)
                    rocketNew.end = end
                    rocketNew.copy = false
                    rocketNew.time = end
                    rocketNew.copy3 = false
                    rocketNew.id = Math.random()
                    rocketNew.cp = item.cp
                    rocketNew.ex_raid_eligible = item.ex_raid_eligible
                    rocketNew.form = item.form
                    rocketNew.gender = item.gender
                    rocketNew.is_exclusive = item.is_exclusive
                    rocketNew.level = item.level
                    rocketNew.move1 = item.move1
                    rocketNew.move2 = item.move2
                    rocketNew.name = item.name
                    rocketNew.pokemon_id = item.pokemon_id
                    rocketNew.sponsor = item.sponsor
                    rocketNew.team = item.team
                    rocketNew.base = item.base

                    rocket.push(rocketNew)
                    inicio = inicio === totalArrayTag - 1 ? 0 : inicio + 1
                }
                if (rocket.length >= 48) {
                    break
                }
            }
            //console.log(rocket)
            this.itens = rocket
            //localStorage.setItem('_rocket', JSON.stringify(rocket))
            this.itensList = coords
            if (!this.itens.length) {
                this.erro = true
            } else {
                this.erro = false
            }
            setTimeout(() => {
                this.loading = false
            }, 300)
        },
        async x() { }
    },
    mounted() {
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'Raids'
        })
    },
}
</script>

<style lang="scss" scoped>
.select-city {
    margin-top: 10px;
}

.title {
    h2 {
        display: flex;
        justify-content: space-between;
        color: #673bb7;
        margin: 10px 0;
        font-size: 23px;
        border-bottom: 1px solid #673bb7;
        font-weight: bold;
    }
}

.type {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 15px;

    .type_content {
        margin: 5px;
        border: 1px solid #ccc;
        padding: 10px 5px;
        box-sizing: border-box;
        font-size: 14px;
        border-radius: 5px;
    }

    .active {
        background-color: #673bb7;
        color: #fff;
    }
}

.reides {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 8px;
    display: flex;
    font-size: 14px;
}

.mystic {
    border: 2px solid #0877e9;
}

.valor {
    border: 2px solid #f3150a;
}

.instinct {
    border: 2px solid #f9d305;
}

.content-img {
    display: flex;
    align-items: center;
    width: 100px;
}

.esq {
    flex-direction: column;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;

    .tempo {
        margin-top: 10px;
        font-size: 14px;
    }

    .coordenada {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 10px;
        background-color: #fec107;
        padding: 3px;
        border-radius: 5px;
        box-sizing: border-box;
    }
}

.content {
    padding: 5px;
    font-size: 15px;
    flex: 1;
    align-items: center;

    .base {
        display: flex;
        font-size: 10px;
        padding: 5px;
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .title {
        margin-right: 5px;
        padding: 5px;
    }
}
</style>

<style lang="scss">
.back {
    background-color: #ffc107;
}

.front {
    background-color: #673ab7;
}

.back,
.front {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

.back,
.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.back,
.flipper,
.front {
    transform-style: preserve-3d;
    border-radius: 5px;
}

*,
:after,
:before {
    border-width: 0;
    border-style: solid;
    border-color: #e2e8f0;
}

.igg {
    max-height: 60px;
    max-width: 60px;
}
.rel {
    right: -19px;
    position: relative;
    bottom: -26px;
}

.check-events {
  margin: 0 auto;
  width: 90%;
  font-size: 11px;
  color: #2c3e50;
  border: 1px solid #673bb7;
  background: #dbd1ec;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}
</style>
