var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('menu.raids')))])]),_c('SelectCity',{staticClass:"select-city",attrs:{"raid":true},on:{"setCity":_vm.setCity}}),_c('div',{staticClass:"type mt-3"},_vm._l((_vm.reids),function(r,k){return _c('div',{key:k,staticClass:"type_content flex justify-center",class:{
          active: _vm.select.filter(i => i.value == r.value).length
        },on:{"click":function($event){$event.preventDefault();return _vm.getDados(r)}}},[_c('div',[(r.img)?_c('img',{staticStyle:{"height":"30px"},attrs:{"src":r.img}}):_vm._e()]),_c('div',{staticClass:"absolute"},[(r.r)?_c('img',{staticStyle:{"height":"30px"},attrs:{"src":_vm.shadow}}):_vm._e()]),_c('div',{staticClass:"rel"},[(r.r)?_c('img',{staticStyle:{"height":"10px"},attrs:{"src":_vm.rocket}}):_vm._e()])])}),0),_c('div',[_c('Loading',{attrs:{"active":_vm.loading}}),(!_vm.itens.length)?_c('div',{staticClass:"check-events"},[_vm._v(" "+_vm._s(_vm.$t('raid.semraids'))+" ")]):_vm._e(),_vm._l((_vm.itens),function(i,k){return _c('div',{key:k,staticClass:"reides",class:[
          { mystic: i.team === 1 },
          { valor: i.team === 2 },
          { instinct: i.team === 3 }
        ]},[_c('div',{staticClass:"content-img flex justify-center"},[_c('img',{staticClass:"igg",attrs:{"src":i.base},on:{"error":_vm.getImage}})]),_c('div',{staticClass:"content"},[_c('div',[_c('strong',[_vm._v("#"+_vm._s(i.pokemon_id)+" "+_vm._s(i.name[0][1]))])]),_c('div',[_c('div',{staticClass:"base"},[_c('div',{staticClass:"left title"},[_c('div',[_vm._v(_vm._s(_vm.$t('raid.tier'))+": "+_vm._s(i.level))]),_c('div',[_vm._v(_vm._s(_vm.$t('raid.cp'))+": "+_vm._s(i.cp))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('raid.gender'))+": "+_vm._s(_vm.getGenderString(i.gender))+" ")]),(i.team)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('raid.team'))+": "),_c('img',{staticStyle:{"height":"20px"},attrs:{"src":_vm.getTime(i.team)}})]):_vm._e()])])])]),_c('div',{staticClass:"esq"},[_c('div',{staticClass:"coordenada"},[_vm._v(_vm._s(i.coord))]),_c('div',[_c('vue-flip',{staticClass:"btn-content",attrs:{"active-click":"","width":"100px","height":"45px"},scopedSlots:_vm._u([{key:"front",fn:function(){return [_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.getCopy(i.coord)}}},[_vm._v(" "+_vm._s(_vm.$t('locais.copiar'))+" ")])]},proxy:true},{key:"back",fn:function(){return [_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.getCopy(i.coord)}}},[_vm._v("=)")])]},proxy:true}],null,true)})],1),_c('div',{staticClass:"tempo"},[_c('div',[_c('countdown',{attrs:{"time":i.time * 1000,"transform":_vm.transform},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" DSP: "+_vm._s(props.minutes)+":"+_vm._s(props.seconds))]}}],null,true)})],1)])])])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }